import { VideoObject, WithContext } from 'schema-dts';

export function videoSchema(props: VideoProps): WithContext<VideoObject> | undefined {
    const { contentUrl, duration, description, name, thumbnailUrl, uploadDate } = props;

    if (!contentUrl || !name) return;

    return {
        '@context': 'https://schema.org',
        '@type': 'VideoObject',
        contentUrl,
        duration,
        description,
        name,
        thumbnailUrl,
        uploadDate,
    };
}

export interface VideoProps {
    contentUrl?: string;
    duration?: any;
    description?: string;
    name?: string;
    thumbnailUrl?: string;
    uploadDate?: string;
}
