import {
    ArticleProps,
    BreadcrumbProps,
    ItemListProps,
    ProductProps,
    VideoProps,
    WebSiteProps,
} from './schemas';
import { OrganizationProps } from './schemas/organization';

export enum SchemaTypes {
    Article = 'Article',
    Breadcrumb = 'Breadcrumb',
    ItemList = 'ItemList',
    Organization = 'Organization',
    Product = 'Product',
    Video = 'Video',
    Website = 'Website',
}

export enum AvailabilityTypes {
    BackOrder = 'https://schema.org/BackOrder',
    Discontinued = 'https://schema.org/Discontinued',
    InStock = 'https://schema.org/InStock',
    InStoreOnly = 'https://schema.org/InStoreOnly',
    LimitedAvailability = 'https://schema.org/LimitedAvailability',
    OnlineOnly = 'https://schema.org/OnlineOnly',
    OutOfStock = 'https://schema.org/OutOfStock',
    PreOrder = 'https://schema.org/PreOrder',
    PreSale = 'https://schema.org/PreSale',
    SoldOut = 'https://schema.org/SoldOut',
}

interface Article {
    type: SchemaTypes.Article;
    props: ArticleProps;
}

interface Breadcrumb {
    type: SchemaTypes.Breadcrumb;
    props: BreadcrumbProps;
}

interface ItemList {
    type: SchemaTypes.ItemList;
    props: ItemListProps;
}

interface Organization {
    type: SchemaTypes.Organization;
    props: OrganizationProps;
}

interface Product {
    type: SchemaTypes.Product;
    props: ProductProps;
}

interface Video {
    type: SchemaTypes.Video;
    props: VideoProps;
}

interface WebSite {
    type: SchemaTypes.Website;
    props: WebSiteProps;
}

export type Schemas = Article | Breadcrumb | ItemList | Organization | Product | Video | WebSite; // | OtherSchemas
