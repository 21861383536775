import { ContactPoint, Organization, PostalAddress, WithContext } from 'schema-dts';

const WEBSITE_URL = process.env.NEXT_PUBLIC_WEBSITE_URL;

export function organizationSchema(
    props: OrganizationProps,
): WithContext<Organization> | undefined {
    const { logo } = props;

    const name = 'Johannes Fog A/S';
    const legalName = name;
    const description =
        'I Fog får du kvalificeret rådgivning til den bedste løsning. Vi hjælper dig med at få overblik og komme i mål med dit byggeprojekt. Lige fra hvad der er godt at vide til vejledning i valg af materialer. \n\n Fog består af ni Trælast & Byggecentre fordelt på Sjælland - i Hørsholm, Fredensborg, Kvistgård, Helsinge, Lyngby, Ølstykke, Herlev, Farum og Vordingborg. I Vordingborg er vi eksperter i salg af jern, stål og andre metaller. \n\n I Fog Lyngbys afdeling for bolig og design kan du finde eksklusive designerlamper, havemøbler af højeste kvalitet og mange andre boligvarer.';
    const sameAs = [
        'https://www.linkedin.com/company/johannes-fog-as',
        'https://www.instagram.com/fogboligdesign/',
        'https://www.facebook.com/boligdesignhus',
        'https://dk.trustpilot.com/review/www.johannesfog.dk',
    ];
    const address: PostalAddress = {
        '@type': 'PostalAddress',
        streetAddress: 'Firskovvej 20',
        addressLocality: 'Lyngby',
        addressCountry: 'DK',
        postalCode: '2800',
    };
    const contactPoint: ContactPoint = {
        '@type': 'ContactPoint',
        telephone: '+4545871001',
        email: 'info@johannesfog.dk',
    };
    const foundingDate = '1920';
    const vatID = 'DK16314439';

    return {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        name,
        url: WEBSITE_URL,
        logo,
        legalName,
        sameAs,
        description,
        address,
        contactPoint,
        foundingDate,
        vatID,
    };
}

export interface OrganizationProps {
    logo?: string;
}
