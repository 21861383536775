import { BreadcrumbList, WithContext } from 'schema-dts';
import { UmbracoTypes } from '~/lib/data-contract';

const WEBSITE_URL = process.env.NEXT_PUBLIC_WEBSITE_URL;

export function breadcrumbSchema(props: BreadcrumbProps): WithContext<BreadcrumbList> | undefined {
    const { items } = props;

    if (items && items.length > 1) {
        return {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: items?.slice(1)?.map((link, index) => ({
                // Skip frontpage link
                '@type': 'ListItem',
                position: index + 1,
                name: link.text,
                item: WEBSITE_URL + link.url,
            })),
        };
    }
}

export interface BreadcrumbProps {
    items: UmbracoTypes.ILink[] | undefined;
}
