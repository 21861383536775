import { Article, Date, WithContext } from 'schema-dts';
import { organizationSchema } from './organization';

export function articleSchema(props: ArticleProps): WithContext<Article> | undefined {
    const { headline, image, datePublished, dateModified, logo } = props;

    const organization = organizationSchema({ logo });

    if (!headline) return;

    return {
        '@context': 'https://schema.org',
        '@type': 'Article',
        headline,
        image,
        datePublished,
        dateModified,
        publisher: organization,
        author: organization,
    };
}

export interface ArticleProps {
    headline?: string;
    image?: string[];
    datePublished?: Date;
    dateModified?: Date;
    logo?: string;
}
