import Head from 'next/head';
import { useMemo } from 'react';
import {
    ArticleProps,
    articleSchema,
    BreadcrumbProps,
    breadcrumbSchema,
    ItemListProps,
    itemListSchema,
    OrganizationProps,
    organizationSchema,
    ProductProps,
    productSchema,
    VideoProps,
    videoSchema,
    WebSiteProps,
    websiteSchema,
} from './schemas';
import { Schemas, SchemaTypes } from './types';

export function Schema(schema: Schemas) {
    const json = useMemo(() => {
        const data = getSchema(schema);
        return data ? JSON.stringify(data) : undefined;
    }, [schema]);

    if (json) {
        return (
            <Head>
                <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: json }} />
            </Head>
        );
    }

    return null;
}

function getSchema(schema: Schemas) {
    switch (schema.type) {
        case SchemaTypes.Article:
            return articleSchema(schema.props as ArticleProps);
        case SchemaTypes.Breadcrumb:
            return breadcrumbSchema(schema.props as BreadcrumbProps);
        case SchemaTypes.ItemList:
            return itemListSchema(schema.props as ItemListProps);
        case SchemaTypes.Organization:
            return organizationSchema(schema.props as OrganizationProps);
        case SchemaTypes.Product:
            return productSchema(schema.props as ProductProps);
        case SchemaTypes.Video:
            return videoSchema(schema.props as VideoProps);
        case SchemaTypes.Website:
            return websiteSchema(schema.props as WebSiteProps);
        default:
            break;
    }
}
