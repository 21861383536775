import { SearchAction, WebSite, WithContext } from 'schema-dts';

const WEBSITE_URL = process.env.NEXT_PUBLIC_WEBSITE_URL;

type SearchActionWithQueryInput = SearchAction & {
    'query-input': string;
};

export function websiteSchema(props: WebSiteProps): WithContext<WebSite> | undefined {
    const { name, searchPath } = props;

    if (!WEBSITE_URL || !searchPath) return;

    const urlTemplate = WEBSITE_URL + searchPath + '?q={search_term_string}';

    return {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        name: name,
        url: WEBSITE_URL,
        potentialAction: [
            {
                '@type': 'SearchAction',
                target: {
                    '@type': 'EntryPoint',
                    urlTemplate,
                },
                'query-input': 'required name=search_term_string',
            } as SearchActionWithQueryInput,
        ],
    };
}

export interface WebSiteProps {
    name?: string;
    searchPath?: string;
}
